/* Center the spinner */
body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f3f3f3;
  }
  
  /* Spinner styles */
  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-left-color: #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Animation for spinning */
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  