.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure the popup is on top of other content */
  }
  
  .popup {
    position: absolute;
    top: 50%; /* Adjust this value to set the distance from the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    background-color: #ffffff;
    /* padding: 10px; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
    z-index: 10000; /* Ensure the popup is above the overlay */
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 2px;
  }

  