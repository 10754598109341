@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.side-bar {
  width: 210px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
  font-family: 'Rubik';
  gap: 10px;
  padding: 5px 10px;
  text-decoration: none;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  margin-top: 5%;
  padding-left: 20px;
  position: relative;
  /* Added to position the hover-text correctly */
}

.title {
  white-space: nowrap;
  font-size: 14px;
  font-family: 'Rubik';
  margin-top: 20%;
}

.side-bar:hover {
  /* border-right: 4px solid white; */
  background: #f2f2f2;
  color: black;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.side-bar.active {
  color: white;
}

.title-div {
  width: 50%;
  display: flex;
  align-items: center;
}

.icon-div {
  width: 10%;
  position: relative;
  /* Added to position the hover-text correctly */
}

.hover-text {
  display: none;
  position: absolute;
  top: 120%;
  transform: translate(-50%, -50%);
  color: black;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 8px;
  z-index: 1000;
  background-color: #f2f2f2;
  align-items: center;
  justify-content: center;
  left: 10px;
  padding-left: 2px;
  padding-right: 2px;
}

.icon-div:hover .hover-text {
  display: block;
}