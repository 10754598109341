.iframeContainer {
    position: absolute;
    /* background-color: black; */
    /* top: 10%;
    left: 20%; */
    width: 78%;
    height: 100%;
  }

  .links{
    font-weight: bold;
    color: black;
  }
  
  .iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
  }
  