* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.MuiTableCell-root {
  font-family: 'Rubik', sans-serif;
}
  
/* Default Theme */
:root {
  --primary-color: #FF5733;
  --primary-color-header: #000000;
  --secondary-color: #FF5733;
  --background-color: #032372;
  --text-color: white;
}

/* Red and Black */
[data-theme="red-black"] {
  --primary-color: #913838;
  --primary-color-header: #000000;
  --secondary-color: #000000;
  --background-color: #913838;
  --text-color: white;
}

/* Blue and Green */
[data-theme="blue-green"] {
  --primary-color: #b1d5aa;
  --primary-color-header: #032372;
  --secondary-color: #032372;
  --background-color: #b1d5aa;
  --text-color: #000000;
}

/* Ocean Green and Yellow */
[data-theme="ocean-green-yellow"] {
  --primary-color: #f3f381;
  --primary-color-header: #008080;
  --secondary-color: #008080;
  --background-color: #f6f69f;
  --text-color: #000000;
}

  .main-container {
    /* flex: 1; */
    /* margin-top: 32%; */
    display: flex;
    background-color: #003366;
    flex-direction: column;
  }
  main {
    padding-left: 20%;
    padding-top: 3%;
  }
  
  /* .title {
    font-size: 3rem;
    display: grid;
    place-items: center;
  } */
  
  /* Sidebar */
  .sidebar {
    width: 18%;
    overflow-wrap: break-word;
    background: var(--background-color);
    color: var(--text-color);
    height: 100%;
    position: fixed;
    overflow-y: auto;
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    overflow: -moz-scrollbars-none;  /* Firefox */
  }

  .sidebar::-webkit-scrollbar {
    width: 0.2em;
    background-color: #F5F5F5;
  }
   
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
  

  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
  }
  .logo {
    font-size: 18px;
    line-height: 0;
  }
  .bars {
    width: 30px;
  }
  .hide {
    display: none;
  }
  
  .icon{
    color: var(--text-color);
  }

  .icon .active{
    color: white;
  }
  
  .routes {
    /* font-size: 90%; */
    margin-top: 35%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .link {
    display: flex;
    color: var(--text-color);
    font: 'Rubik';
    gap: 10px;
    padding: 5px 10px;
    text-decoration: none;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link:hover {
    /* border-right: 4px solid white; */
    background: var(--secondary-color);
    color: white;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link.active {
    color: white;
  }
  .active {
    /* border-left: 5px solid #FFB800; */
    background: var(--secondary-color);
  }
  .link_text {
    white-space: nowrap;
    font-size: 15px;
    font-family: 'Rubik';
    margin-top: 2%;
  }
  
  .menu {
    display: flex;
    color: var(--text-color);
    cursor: pointer;
    padding: 5px 10px;
    /* border-right: 4px solid transparent; */
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
  }
  
  
  .menu_item {
    display: flex;
    gap: 10px;
  }
  .menu_container {
    display: flex;
    flex-direction: column;
  }
  .menu_container .link {
    padding-left: 20%;
   
  }

  .header {
    background: var(--background-color);
    cursor: pointer;
  }
  
  .header:hover {
    color: var(--primary-color);
  }
  
  .usernameMenu{
    margin-top: 1%;
    margin-left: 3%;
  }
  
  .btn {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
  
  .btn:hover {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color:#000000 !important;
  }
  

  