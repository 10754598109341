.navigation {
    font-weight: bold;
    cursor: pointer;
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* vertical-align: middle; */
    /* gap: 5px; */
    /* padding-top: 1%; */
    font-size: 12px;
    align-items: center;
    font-family: "Rubik";

}

.navigation:hover {
    color: #00A0F0;
}

.backarrow {
    padding-top: 1%;
    padding-bottom: 1%;
    font-size: 28px;
    font-family: "Rubik";
}



