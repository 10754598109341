.createvehicle {
  align-items: center;
  /* border: 2px solid #161717a8; */

}

h4 {
  color: #19172C;
  background-color: #F7F7F7;
  font-family: 'Rubik';
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  /* padding-left: 30px;
    padding-top: 0px; */

  text-align: center;
}

.links {
  font-weight: 600;
  font-family: 'Rubik';
  padding-left: 20%;
  text-decoration-line: #4822B4;
}

.col-sm-8 :disabled {
  background-color: #E6FAFD;
}

.dropdown-menu {
  background-color: #f8f9fa; /* replace with your desired color */
}

.name {
  font-weight: bold;
  cursor: pointer;
}

.separator {
  color: black;
}
