form {
  /* border: 2px solid #161717a8; */
  /* background: #edfafca8; */
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Rubik';
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  /* max-width: 600px; */
  /* padding: 30px 30px; */
}

.domain {
  padding-left: 0%;
}

.brand {
  padding: 30px 30px;
  border-radius: 3%;
  border: 1px solid #161717a8;
}

.policy {
  padding: 30px 30px;
  border-radius: 3%;
  border: 1px solid #161717a8;
}

h2 {
  color: #0d0e0e;
  background-color: #F7F7F7;
  font-family: 'Rubik';
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  /* padding-left: 30px;
  padding-top: 0px; */

  text-align: left;
}

h5 {
  color: #0d0e0e;
  background-color: #F7F7F7;
  font-family: 'Rubik';
  font-size: 26px;
  font-weight: 600;
}

/* body {
  background: #07edede8;
} */

button {
  min-width: 150px;
  max-width: 430px;
}

.buttons2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-items: center;
}

.buttons1 {
  padding-left: 5%;
}


.check {
  padding-left: 1%;
}

.form-control {
  border: 1px solid #000000;
  border-radius: 6px;
}

.form-check-input {
  border-color: #000000;

}

.form-check-input:checked {
  background-color: #19172C;
  border-color: #19172C;
  box-shadow: none;
}

.form-control:focus {
  border-color: #0d0e0e;
  box-shadow: none;
}

.input-group-text {
  background-color: #E6FAFD;
  border-color: #000000;
}

.form-control::-webkit-file-upload-button {
  background-color: #E6FAFD;

}

.error-message {
  color: red;
}

.success-message {
  color: rgb(9, 180, 6);
}