.main-header-container {
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    background-color: #fff; /* White background color */
    padding: 10px 15px; /* Adjust padding as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
    height: 45px;
    border-radius: 5px; 
    margin-top: 5px;   
    padding-right: 20px;
}

.search-box-tenant {
    height: 30px;
    width: 300px;
    border-radius: 5px;
    border: 0.5px solid #ccc;
    padding-left: 15px;
    outline: none;
    background-color: #f2f2f2;
    font-family: "Rubik";
    font-size: 14px;
}

.status-selector {
    height: 25px;
    border-radius: 5px;
    border: 0.5px solid #ccc;
    padding: 0 10px;
    outline: none;
    background-color: #f2f2f2;
    margin-left: 10px;
    width: 150px; /* Adjust width as needed */
    font-family: "Rubik";
    font-size: 14px;
}

.filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "Rubik";
}

