.createvehicle {
   border-radius: 10px;
   box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  }
  
  h4 {
    color: #19172C;
    background-color: #F7F7F7;
    font: 'Rubik';
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    /* padding-left: 30px;
      padding-top: 0px; */
  
    text-align: center;
    font-family: "Rubik";
  }
  
  .links {
    font-weight: 600;
    font-family: 'Rubik';
    /* padding-left: 20%; */
    text-decoration-line: #4822B4;
  }
  
  .col-sm-8 :disabled {
    background-color: #E6FAFD;
  }
  
  .dropdown-menu {
    background-color: #f8f9fa; /* replace with your desired color */
  }
  
  .name {
    font-weight: bold;
    cursor: pointer;
  }
  
  .separator {
    color: black;
  }

  .input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .input-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .input-title {
    font-size: 13px;
    font-weight: 600;
    font-family: "Rubik";
  }

  .input {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: #f2f2f2;
    outline: none;
    border: 0.5px;
    padding-left: 5px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Rubik";
  }

  .submit-button {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  .error-message {
    font-size: 12px;
    color: rgb(193, 33, 33);
    font-weight: 400;
    font-family: 'Rubik';
    margin-left: 2px;
    margin-top: 2px;
  }
  