@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.container-vin {
  width: 100%;
  height: 100%;
}

.header-vin {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-left: 15px;
  padding-right: 15px;
}

.vin-input-search {
  height: 30px;
  width: 200px;
  border-radius: 5px;
  border: 0.5px solid #ccc;
  padding-left: 15px;
  outline: none;
  background-color: #f2f2f2;
  font-family: "Rubik";
  font-size: 14px;
}

.select-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  color: black;
  font-family: "Rubik";
  width: 20%;
}

.vin-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 350px;
  overflow: scroll;
  padding: 5px;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
  padding-right: 15px;
  padding-left: 15px;
  align-items: center;
  justify-content: center;
}

.vin-list::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.vin-row {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.vin-item {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 12px;
  font-family: "Rubik";
  color: black;
  display: flex;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.vin-item:last-child {
  margin-right: 0;
}

.vin-page {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 28%; */
  padding-right: 5%;

}