.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
}

.icons {
  display: flex;
  color: #4822B4;
}

.pagenate {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 500;
  box-shadow: 1px;
}

.paginationBttns {
  width: 5px;
  height: 5px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationBttns a {
  padding: 5px 8px;
  margin: 8px;
  border-style: hidden;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #f2f2f2;
  color: black;
  cursor: pointer;
  margin-bottom: 5px;
}

.paginationBttns :hover {
  color: white;
}

.paginationBttns a:hover {
  color: white;
  background-color: #FF5733;
}

.paginationActive a {
  color: white;
  background-color: #FF5733;
  /* border-radius: 50%;
  width: 9%;
  height: 3%; 
  background: #19172C; */

}

.paginationActive {
  color: rgb(248, 242, 242);

}

.paginationDisabled a {
  color: grey;

}

.paragraph {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4822B4;
}