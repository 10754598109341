
.domain-input-container {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    font-family: "Rubik";
    margin-top: 10px;
  }
  
  .domain-label {
    margin-bottom: 5px;
  }
  
  .domain-input-wrapper {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: #f2f2f2;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10px;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "Rubik";
  }
  
  .domain-input {
    border: none;
    outline: none;
    height: 30px;
    width: 25%;
    font-size: 12px;
    font-weight: 600;
    border-radius: 5px;
    padding-left: 5px;
    font-family: "Rubik";
  }
  
  .check-availability {
    border-radius: 5px;
    font-size: 8px;
    color: gray;
    font-weight: 500;
    font-family: "Rubik";
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .renew-icon {
    font-size: 18px;
    color: var(--primary-color);
    font-family: "Rubik";
  }
  
  .error-message {
    font-size: 10px;
    font-weight: 600;
    color: red;
    font-family: "Rubik";
  }

  .check-button {
    width: 110px;
    height: 30px;
    background-color:#2C3B8E ;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 10px;
    font-family: "Rubik";
    color: white;
  }

  .rotate {
    animation: rotate 1s infinite linear;
    font-size: 4px;
    color: white;
    font-family: "Rubik";
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  