.loading-container {
    height: 100%;
    width: 120%;
    background-color: rgba(58, 58, 50, 0.8);
    position: absolute;
    bottom: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.popup-box {
    width: 260px;
    height: 350px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    font-size: 14px;
    font-family: "Rubik";
    color: #19172C;
    text-align: center;
    font-weight: 600;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #19172C;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-top: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}